$white: #ffffff
$flamingo: #f25a21
$sundance: #c5a95e
$alizarin-crimson: #e72221
$turquoise: #30c6c6
$sushi: #7da747
$dodger-blue: #2681ff
$shark: #1d1d1f
$dark-shark: #0d2033
$pale-sky: #6c7680
$gray-chateau: #a9b0b8
$athens-gray: #e6e9eb
$porcelain: #f4f6f7
$pale-sky-blue: #dae1e6
$navy: #001e40
$strawberry: #ff2638
$green-dark: #22b53a
$dark-grey: #2E3338

\:root
  --danger-background: #{$strawberry}
  --toastr-success: #{$green-dark}
