*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

:root {
  --danger-background: #ff2638;
  --toastr-success: #22b53a;
}

.color-white {
  color: #ffffff !important;
}

.color-dark {
  color: var(--dark-font);
}

.color-light {
  color: var(--light-font);
}

:root {
  --danger-background: #ff2638;
  --toastr-success: #22b53a;
}

h1, h2, h3, h4, h5 {
  color: var(--primary-color);
}

h1 {
  font-size: 54px;
  font-weight: 700;
  line-height: 1.2;
}
@media only screen and (max-width: 767px) {
  h1 {
    font-size: 36px;
    line-height: 1.31;
  }
}

h2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.31;
}
@media only screen and (max-width: 767px) {
  h2 {
    font-size: 24px;
    line-height: 1.2;
  }
}

h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
}

h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
}

h5 {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
}

:root {
  --danger-background: #ff2638;
  --toastr-success: #22b53a;
}

p, span {
  font-weight: normal;
  line-height: 1.6;
  color: var(--primary-color);
}
p.text, span.text {
  font-size: 16px;
}
p.text--lg, span.text--lg {
  font-size: 18px;
}

.caption {
  font-size: 14px;
  line-height: 1.4;
  color: var(--light-font);
}
.caption-italic {
  font-size: 13px;
  font-style: italic;
  line-height: 1.2;
}

.disclaimer-text {
  font-size: 11px;
  line-height: 1.4;
  color: var(--disclamer-font);
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.cursive-highlight {
  font-style: italic;
  color: var(--dark-font);
  font-weight: 600;
}

.amount {
  font-size: 40px;
  font-weight: 600;
  color: var(--main-color);
}

.text-italic {
  font-style: italic;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-700 {
  font-weight: 700;
}

.number {
  font-weight: 700;
  font-size: 24px;
}
.number--lg {
  font-weight: 700;
  font-size: 64px;
}
@media (max-width: 660px) {
  .number--lg {
    font-size: 48px;
  }
}

:root {
  --danger-background: #ff2638;
  --toastr-success: #22b53a;
}

:root {
  --main-font-family: Figtree, sans-serif;
  --primary-color: #1d1d1f;
  --dark-font: #0d2033;
  --light-font: #6c7680;
  --disclamer-font: #a9b0b8;
  --main-color: #f25a21;
  --shade-color: hsl(16.3636363636deg, 91.1489361702%, 44.7549019608%);
  --tint-one-color: hsl(16.3636363636deg, 88.9361702128%, 92%);
  --tint-two-color: hsl(16.3636363636deg, 88.9361702128%, 80%);
  --tint-three-color: hsl(16.3636363636deg, 98.9361702128%, 72%);
}
:root .flamingo-theme {
  --main-color: #f25a21;
  --shade-color: hsl(16.3636363636deg, 91.1489361702%, 44.7549019608%);
  --tint-one-color: hsl(16.3636363636deg, 88.9361702128%, 92%);
  --tint-two-color: hsl(16.3636363636deg, 88.9361702128%, 80%);
  --tint-three-color: hsl(16.3636363636deg, 98.9361702128%, 72%);
}
:root .sundance-theme {
  --main-color: #c5a95e;
  --shade-color: hsl(43.6893203883deg, 57.6255707763%, 47.3588235294%);
  --tint-one-color: hsl(43.6893203883deg, 47.0319634703%, 92%);
  --tint-two-color: hsl(43.6893203883deg, 47.0319634703%, 80%);
  --tint-three-color: hsl(43.6893203883deg, 57.0319634703%, 72%);
}
:root .alizarin-crimson-theme {
  --main-color: #e72221;
  --shade-color: hsl(0.303030303deg, 84.3902439024%, 42.9647058824%);
  --tint-one-color: hsl(0.303030303deg, 80.487804878%, 92%);
  --tint-two-color: hsl(0.303030303deg, 80.487804878%, 80%);
  --tint-three-color: hsl(0.303030303deg, 90.487804878%, 72%);
}
:root .turquoise-theme {
  --main-color: #30c6c6;
  --shade-color: hsl(180deg, 68.7804878049%, 40.0352941176%);
  --tint-one-color: hsl(180deg, 60.9756097561%, 92%);
  --tint-two-color: hsl(180deg, 60.9756097561%, 80%);
  --tint-three-color: hsl(180deg, 70.9756097561%, 72%);
}
:root .sushi-theme {
  --main-color: #7da747;
  --shade-color: hsl(86.25deg, 52.268907563%, 38.7333333333%);
  --tint-one-color: hsl(86.25deg, 40.3361344538%, 92%);
  --tint-two-color: hsl(86.25deg, 40.3361344538%, 80%);
  --tint-three-color: hsl(86.25deg, 50.3361344538%, 72%);
}
:root .dodger-blue-theme {
  --main-color: #2681ff;
  --shade-color: hsl(214.8387096774deg, 100%, 47.6843137255%);
  --tint-one-color: hsl(214.8387096774deg, 100%, 92%);
  --tint-two-color: hsl(214.8387096774deg, 100%, 80%);
  --tint-three-color: hsl(214.8387096774deg, 100%, 72%);
}
:root .dark-grey-theme {
  --main-color: #2E3338;
  --shade-color: hsl(210deg, 27.8431372549%, 16.6%);
  --tint-one-color: hsl(210deg, 9.8039215686%, 92%);
  --tint-two-color: hsl(210deg, 9.8039215686%, 80%);
  --tint-three-color: hsl(210deg, 19.8039215686%, 72%);
  --tint-four-color: tint4(#2E3338);
}

html {
  box-sizing: border-box;
}

body {
  min-width: 360px;
  margin: 0;
  color: var(--primary-color);
  font-family: var(--main-font-family);
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:hover {
  text-decoration: none;
}

input, textarea, select, button {
  font-weight: 500;
  outline: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1, h2, h3, h4, h5, h6, p, ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

input, select, textarea {
  transition: background-color 30000s, color 30000s, filter 30000s;
}
input:-webkit-autofill, select:-webkit-autofill, textarea:-webkit-autofill {
  animation-duration: 30000s;
  animation-name: onautofillstart;
}
input:not(:-webkit-autofill), select:not(:-webkit-autofill), textarea:not(:-webkit-autofill) {
  animation-duration: 30000s;
  animation-name: onautofillcancel;
}
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button, select::-webkit-outer-spin-button, select::-webkit-inner-spin-button, textarea::-webkit-outer-spin-button, textarea::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number], select[type=number], textarea[type=number] {
  -moz-appearance: textfield;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.grid {
  padding-left: 16px;
  padding-right: 16px;
}
.grid-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
}
.grid-col {
  padding-left: 16px;
  padding-right: 16px;
}

.inline-svg {
  display: inline-block;
}
.inline-svg svg {
  width: inherit;
  height: inherit;
  fill: inherit;
}
.inline-svg__icon {
  display: inline-block;
}
.inline-svg__icon.btn-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #f4f6f7;
  cursor: pointer;
  transition: all 0.3s;
}
.inline-svg__icon.btn-circle:hover {
  background-color: var(--tint-one-color);
}
.inline-svg__icon.btn-circle:hover svg {
  fill: var(--main-color);
}
.inline-svg__icon svg {
  width: 24px;
  height: 24px;
  fill: #a9b0b8;
  transition: all 0.3s;
}
.inline-svg--program svg .base-color1 {
  fill: #80878E;
}
.inline-svg--program svg .base-color2 {
  fill: #CED4D9;
}

.programs-item.active .inline-svg--program .base-color1, .programs-item:hover .inline-svg--program .base-color1 {
  fill: var(--main-color);
}
.programs-item.active .inline-svg--program .base-color2, .programs-item:hover .inline-svg--program .base-color2 {
  fill: var(--tint-three-color);
}

@keyframes onautofillstart {
  0% {
    opacity: 1;
  }
}
@keyframes onautofillcancel {
  0% {
    opacity: 1;
  }
}
.m-t-4 {
  margin-top: 4px !important;
}

.p-t-4 {
  padding-top: 4px !important;
}

.m-b-4 {
  margin-bottom: 4px !important;
}

.p-b-4 {
  padding-bottom: 4px !important;
}

.m-l-4 {
  margin-left: 4px !important;
}

.p-l-4 {
  padding-left: 4px !important;
}

.m-r-4 {
  margin-right: 4px !important;
}

.p-r-4 {
  padding-right: 4px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.m-t-8 {
  margin-top: 8px !important;
}

.p-t-8 {
  padding-top: 8px !important;
}

.m-b-8 {
  margin-bottom: 8px !important;
}

.p-b-8 {
  padding-bottom: 8px !important;
}

.m-l-8 {
  margin-left: 8px !important;
}

.p-l-8 {
  padding-left: 8px !important;
}

.m-r-8 {
  margin-right: 8px !important;
}

.p-r-8 {
  padding-right: 8px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.m-t-16 {
  margin-top: 16px !important;
}

.p-t-16 {
  padding-top: 16px !important;
}

.m-b-16 {
  margin-bottom: 16px !important;
}

.p-b-16 {
  padding-bottom: 16px !important;
}

.m-l-16 {
  margin-left: 16px !important;
}

.p-l-16 {
  padding-left: 16px !important;
}

.m-r-16 {
  margin-right: 16px !important;
}

.p-r-16 {
  padding-right: 16px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.m-t-24 {
  margin-top: 24px !important;
}

.p-t-24 {
  padding-top: 24px !important;
}

.m-b-24 {
  margin-bottom: 24px !important;
}

.p-b-24 {
  padding-bottom: 24px !important;
}

.m-l-24 {
  margin-left: 24px !important;
}

.p-l-24 {
  padding-left: 24px !important;
}

.m-r-24 {
  margin-right: 24px !important;
}

.p-r-24 {
  padding-right: 24px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.m-r-25 {
  margin-right: 25px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.m-t-32 {
  margin-top: 32px !important;
}

.p-t-32 {
  padding-top: 32px !important;
}

.m-b-32 {
  margin-bottom: 32px !important;
}

.p-b-32 {
  padding-bottom: 32px !important;
}

.m-l-32 {
  margin-left: 32px !important;
}

.p-l-32 {
  padding-left: 32px !important;
}

.m-r-32 {
  margin-right: 32px !important;
}

.p-r-32 {
  padding-right: 32px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.m-b-35 {
  margin-bottom: 35px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.m-l-35 {
  margin-left: 35px !important;
}

.p-l-35 {
  padding-left: 35px !important;
}

.m-r-35 {
  margin-right: 35px !important;
}

.p-r-35 {
  padding-right: 35px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}

.m-r-40 {
  margin-right: 40px !important;
}

.p-r-40 {
  padding-right: 40px !important;
}

.m-t-45 {
  margin-top: 45px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.m-b-45 {
  margin-bottom: 45px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.m-l-45 {
  margin-left: 45px !important;
}

.p-l-45 {
  padding-left: 45px !important;
}

.m-r-45 {
  margin-right: 45px !important;
}

.p-r-45 {
  padding-right: 45px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

.p-l-50 {
  padding-left: 50px !important;
}

.m-r-50 {
  margin-right: 50px !important;
}

.p-r-50 {
  padding-right: 50px !important;
}

.m-t-54 {
  margin-top: 54px !important;
}

.p-t-54 {
  padding-top: 54px !important;
}

.m-b-54 {
  margin-bottom: 54px !important;
}

.p-b-54 {
  padding-bottom: 54px !important;
}

.m-l-54 {
  margin-left: 54px !important;
}

.p-l-54 {
  padding-left: 54px !important;
}

.m-r-54 {
  margin-right: 54px !important;
}

.p-r-54 {
  padding-right: 54px !important;
}

.m-t-56 {
  margin-top: 56px !important;
}

.p-t-56 {
  padding-top: 56px !important;
}

.m-b-56 {
  margin-bottom: 56px !important;
}

.p-b-56 {
  padding-bottom: 56px !important;
}

.m-l-56 {
  margin-left: 56px !important;
}

.p-l-56 {
  padding-left: 56px !important;
}

.m-r-56 {
  margin-right: 56px !important;
}

.p-r-56 {
  padding-right: 56px !important;
}

.m-t-65 {
  margin-top: 65px !important;
}

.p-t-65 {
  padding-top: 65px !important;
}

.m-b-65 {
  margin-bottom: 65px !important;
}

.p-b-65 {
  padding-bottom: 65px !important;
}

.m-l-65 {
  margin-left: 65px !important;
}

.p-l-65 {
  padding-left: 65px !important;
}

.m-r-65 {
  margin-right: 65px !important;
}

.p-r-65 {
  padding-right: 65px !important;
}

.m-t-72 {
  margin-top: 72px !important;
}

.p-t-72 {
  padding-top: 72px !important;
}

.m-b-72 {
  margin-bottom: 72px !important;
}

.p-b-72 {
  padding-bottom: 72px !important;
}

.m-l-72 {
  margin-left: 72px !important;
}

.p-l-72 {
  padding-left: 72px !important;
}

.m-r-72 {
  margin-right: 72px !important;
}

.p-r-72 {
  padding-right: 72px !important;
}

.m-t-75 {
  margin-top: 75px !important;
}

.p-t-75 {
  padding-top: 75px !important;
}

.m-b-75 {
  margin-bottom: 75px !important;
}

.p-b-75 {
  padding-bottom: 75px !important;
}

.m-l-75 {
  margin-left: 75px !important;
}

.p-l-75 {
  padding-left: 75px !important;
}

.m-r-75 {
  margin-right: 75px !important;
}

.p-r-75 {
  padding-right: 75px !important;
}

:root {
  --danger-background: #ff2638;
  --toastr-success: #22b53a;
}

button {
  padding: 0;
  font-family: var(--main-font-family);
  border: none;
  box-shadow: none;
  background-color: transparent;
}
button:focus, button:active {
  outline: none;
}
button:hover {
  cursor: pointer;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 21px 40px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: #ffffff;
  border-radius: 8px;
  transition: all 0.3s;
}
@media (max-width: 540px) {
  .btn {
    padding: 21px 30px;
  }
}
.btn:hover {
  background-position: right center;
  cursor: pointer;
}
.btn:focus {
  outline: none;
}
.btn:active, .btn.active {
  background-position: left center;
}
.btn:disabled, .btn.disabled {
  cursor: no-drop;
}
.btn--default {
  background-color: var(--main-color);
}
.btn--default:hover {
  background-color: var(--shade-color);
}
.btn--default:focus {
  background-color: var(--shade-color);
}
.btn--default:active, .btn--default.active {
  background-color: var(--shade-color);
}
.btn--default:disabled, .btn--default.disabled {
  background-color: #e6e9eb;
}
.btn--white {
  color: #1d1d1f;
  background-color: #ffffff;
}
.btn--white:hover, .btn--white:active, .btn--white.active {
  color: var(--shade-color);
  background-color: var(--tint-one-color);
}
.btn--white:focus {
  color: var(--shade-color);
  background-color: var(--tint-one-color);
}
.btn--white:disabled, .btn--white.disabled {
  color: #a9b0b8;
  background-color: #ffffff;
}
.btn--grey {
  color: #1d1d1f;
  background-color: #f4f6f7;
}
.btn--grey:hover, .btn--grey:active, .btn--grey.active {
  color: var(--main-color);
  background-color: var(--tint-one-color);
}
.btn--grey:focus {
  color: var(--main-color);
  background-color: var(--tint-one-color);
}
.btn--grey:disabled, .btn--grey.disabled {
  color: #a9b0b8;
  background-color: #f4f6f7;
}
.btn-group {
  display: inline-block;
  padding: 4px 2px;
  border-radius: 8px;
  background-color: var(--shade-color);
}
.btn-group .btn {
  margin: 0 2px;
  padding: 13px 24px;
  border-radius: 5px;
}
.btn-group .btn:hover, .btn-group .btn:focus, .btn-group .btn:active, .btn-group .btn.active {
  color: #ffffff;
  background-color: var(--main-color);
}
.btn-group .btn:disabled, .btn-group .btn.disabled {
  color: rgb(255, 255, 255) !important;
  background-color: unset;
}
.btn-group .btn--default {
  background-color: #f4f6f7;
  color: #1d1d1f;
}
.btn-group .btn--default:hover, .btn-group .btn--default:focus, .btn-group .btn--default:active, .btn-group .btn--default.active {
  background-color: #f4f6f7;
  color: #1d1d1f;
}
.btn .btn-spinner {
  position: relative;
  display: inline-block;
  width: 19px;
  height: 19px;
  margin-left: -22px;
  border-radius: 50%;
  animation: 0.9s linear infinite three-quarters;
  transition: 0.4s margin ease-out, 0.2s opacity ease-out;
  background-image: conic-gradient(from 0.25turn, rgba(255, 255, 255, 0), #ffffff);
  opacity: 0;
}
.btn .btn-spinner::before {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: var(--main-color);
}
.btn.is-loading .btn-spinner {
  margin-left: 10px;
  transition: 0.2s margin ease-in, 0.4s opacity ease-in;
  opacity: 1;
}
.btn.is-loading.btn--default {
  background: #e6e9eb;
  color: #a9b0b8;
}
.btn.is-loading.btn--default .btn-spinner {
  background-image: conic-gradient(from 0.25turn, rgba(242, 90, 33, 0), var(--main-color));
}
.btn.is-loading.btn--default .btn-spinner::before {
  background: #e6e9eb;
}
.btn.is-loading.btn--white {
  background-color: #ffffff;
  color: rgba(29, 29, 31, 0.5);
}
.btn.is-loading.btn--white .btn-spinner {
  background-image: conic-gradient(from 0.25turn, rgba(242, 90, 33, 0), var(--main-color));
}
.btn.is-loading.btn--white .btn-spinner::before {
  background: #ffffff;
}
.btn.is-loading.btn--grey {
  background-color: #f4f6f7;
  color: rgba(29, 29, 31, 0.5);
}
.btn.is-loading.btn--grey .btn-spinner {
  background-image: conic-gradient(from 0.25turn, rgba(242, 90, 33, 0), var(--main-color));
}
.btn.is-loading.btn--grey .btn-spinner::before {
  background: #f4f6f7;
}

@keyframes three-quarters {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
:root {
  --danger-background: #ff2638;
  --toastr-success: #22b53a;
}

.form-group {
  padding: 16px;
}

.input-group {
  position: relative;
}
.input-group .icon {
  position: absolute;
  width: 24px;
  height: 24px;
  z-index: 1;
  left: 16px;
  top: 19px;
}
.input-group .icon svg {
  width: 100%;
  height: 100%;
}
.input-group .form-control {
  width: 100%;
  padding: 19px 19px 19px 48px;
  font-family: "Figtree";
  font-size: 20px;
  font-weight: normal;
  line-height: 1.3;
  color: #1d1d1f;
  border: unset;
  box-shadow: inset 0px 0px 0px 1px #a9b0b8;
  border-radius: 5px;
  transition: box-shadow 0.3s;
}
.input-group .form-control::placeholder {
  color: #a9b0b8;
}
.input-group .form-control:focus {
  box-shadow: inset 0px 0px 0px 2px var(--main-color);
}
.input-group .form-control.error, .input-group .form-control.ng-invalid.ng-dirty {
  box-shadow: inset 0px 0px 0px 2px #e01010;
}
.input-group .form-control:disabled {
  color: #a9b0b8;
  box-shadow: inset 0px 0px 0px 1px #e6e9eb;
  background-color: #f4f6f7;
  cursor: no-drop;
}
.input-group .error {
  position: absolute;
  bottom: -24px;
}
.input-group .error span {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
  color: #e01010;
}

.stepper__title {
  margin-bottom: 40px;
}
@media (max-width: 450px) {
  .stepper__title {
    margin-bottom: 24px;
    font-size: 24px;
  }
}
.stepper__description {
  margin: -32px 0 40px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: #1d1d1f;
}
@media (max-width: 450px) {
  .stepper__description {
    margin-top: -10px;
  }
}
.stepper__btn-group {
  margin: 0 -16px;
}
@media (max-width: 540px) {
  .stepper__btn-group {
    margin: 0;
  }
}
.stepper__btn-group .btn {
  flex-direction: column;
  margin: 0 16px;
  min-width: 160px;
}
@media (max-width: 780px) {
  .stepper__btn-group .btn {
    margin: 8px;
  }
}
.stepper__btn-group.column {
  display: flex;
  flex-direction: column;
  min-width: 540px;
}
@media (max-width: 560px) {
  .stepper__btn-group.column {
    width: 100%;
    min-width: unset;
  }
}
.stepper__btn-group.column .btn {
  margin: 8px 0;
}
.stepper__btn-group.images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.stepper__btn-group.images .btn {
  padding: 32px 15px;
  border-radius: 16px;
}
@media (max-width: 780px) {
  .stepper__btn-group.images .btn {
    padding: 10px 8px;
    min-width: 40%;
    width: 45%;
    background-color: #f4f6f7;
    border-radius: 8px;
  }
  .stepper__btn-group.images .btn img {
    width: 100%;
  }
}
.stepper__btn-group.images .btn:hover .active {
  display: block;
}
.stepper__btn-group.images .btn:hover .image {
  display: none;
}
.stepper__btn-group.images .btn img {
  margin-bottom: 25px;
}
.stepper__btn-group.images .btn img.active {
  display: none;
}
.stepper__images-group {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -16px;
}
@media (max-width: 450px) {
  .stepper__images-group {
    margin: 0;
  }
}
.stepper__images-group .image-item {
  display: flex;
  flex-direction: column;
  padding: 24px 40px 32px;
  margin: 0 16px;
  width: 195px;
  border-radius: 16px;
  background-color: transparent;
  transition: background-color 0.3s;
  cursor: pointer;
}
.stepper__images-group .image-item:hover.bad {
  background-color: #fbdbdb;
}
.stepper__images-group .image-item:hover.poor {
  background-color: #fde6de;
}
.stepper__images-group .image-item:hover.fair {
  background-color: #fff0cc;
}
.stepper__images-group .image-item:hover.good {
  background-color: #dff5dc;
}
.stepper__images-group .image-item:hover.great {
  background-color: #dbf2ff;
}
@media (max-width: 451px) {
  .stepper__images-group .image-item .image {
    display: none;
  }
}
.stepper__images-group .image-item .image.mobile {
  display: none;
}
@media (max-width: 450px) {
  .stepper__images-group .image-item .image.mobile {
    display: block;
  }
}
.stepper__images-group .image-item .title {
  margin: 24px 0 16px;
  line-height: 1;
}
@media (max-width: 450px) {
  .stepper__images-group .image-item {
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 8px 16px;
    margin: 8px 0;
    border-radius: 8px;
  }
  .stepper__images-group .image-item img {
    width: 40px;
  }
  .stepper__images-group .image-item .title {
    margin: 0;
    margin-left: 16px;
  }
  .stepper__images-group .image-item .score {
    margin-left: auto;
  }
  .stepper__images-group .image-item.bad {
    background-color: #fbdbdb;
  }
  .stepper__images-group .image-item.poor {
    background-color: #fde6de;
  }
  .stepper__images-group .image-item.fair {
    background-color: #fff0cc;
  }
  .stepper__images-group .image-item.good {
    background-color: #dff5dc;
  }
  .stepper__images-group .image-item.great {
    background-color: #dbf2ff;
  }
}

.modal-open {
  overflow: hidden;
}

.modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  padding: 20px 0;
  background-color: rgba(0, 30, 64, 0.6);
  outline: 0;
}

.modal-content {
  position: relative;
  width: 100%;
  pointer-events: auto;
  background-color: #ebeff2;
  background-clip: padding-box;
  border-radius: 8px;
  outline: 0;
  width: 560px;
}
@media (max-width: 560px) {
  .modal-content {
    width: calc(100vw - 32px);
  }
}

.modal.fade-anim {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.modal.fade-anim.in {
  opacity: 1;
}
.modal.fade-anim.in .modal-content {
  animation: modal-fade-in-top 0.3s;
}

.modal.fade-anim.scroll {
  align-items: flex-start;
  overflow-y: auto;
}

@keyframes modal-fade-in-top {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.toast-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  z-index: 1001;
  top: 0;
  right: 0;
}

.notify {
  display: block !important;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6px;
  padding: 20px 0;
  background-image: none;
  overflow: hidden;
}
.notify.toast-success {
  background-color: var(--toastr-success);
}
.notify.toast-warning {
  background-color: var(--danger-background);
}
.notify.toast-warning .toast-close-button {
  position: absolute;
  right: 0.5em;
  top: 1.5em;
  width: 15px;
  height: 15px;
  padding: 0;
  margin: 0;
  background-image: url("/assets/icons/close.svg");
}
.notify.toast-warning .toast-close-button:hover, .notify.toast-warning .toast-close-button:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}
.notify.toast-warning .toast-close-button span {
  display: none;
}
.notify .toast-title {
  color: #ffffff;
  text-align: center;
}
.notify .toast-message {
  width: 100%;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
}