@import "src/assets/sass/_variables"
@import "src/assets/sass/mixins/_all"

p, span
  font-weight: normal
  line-height: 1.6
  color: var(--primary-color)

  &.text
    font-size: 16px

    &--lg
      font-size: 18px

.caption
  font-size: 14px
  line-height: 1.4
  color: var(--light-font)

  &-italic
    font-size: 13px
    font-style: italic
    line-height: 1.2

.disclaimer-text
  font-size: 11px
  line-height: 1.4
  color: var(--disclamer-font)

.text-overflow
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

.text-center
  text-align: center

.text-right
  text-align: right

.text-left
  text-align: left

.cursive-highlight
  font-style: italic
  color: var(--dark-font)
  font-weight: 600

.amount
  font-size: 40px
  font-weight: 600
  color: var(--main-color)

.text-italic
  font-style: italic

.f-w-600
  font-weight: 600

.f-w-700
  font-weight: 700

.number
  font-weight: 700
  font-size: 24px

  &--lg
    font-weight: 700
    font-size: 64px

    +maxW(660px)
      font-size: 48px
