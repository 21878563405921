$spaceamounts: (4, 5, 8, 10, 15, 16, 20, 24, 25, 30, 32, 35, 40, 45, 50, 54, 56, 65, 72, 75)
$sides: (top, bottom, left, right) // Leave this variable alone

@each $space in $spaceamounts
  @each $side in $sides
    .m-#{str-slice($side, 0, 1)}-#{$space}
      margin-#{$side}: #{$space}px !important

    .p-#{str-slice($side, 0, 1)}-#{$space}
      padding-#{$side}: #{$space}px !important
