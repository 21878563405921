
.modal-open
  overflow: hidden

.modal
  display: flex !important
  align-items: center
  justify-content: center
  position: fixed
  z-index: 1000
  bottom: 0
  left: 0
  right: 0
  top: 0
  height: 100%
  padding: 20px 0
  background-color: rgba(0, 30, 64, 0.6)
  outline: 0

.modal-content
  position: relative
  width: 100%
  pointer-events: auto
  background-color: #ebeff2
  background-clip: padding-box
  border-radius: 8px
  outline: 0
  width: 560px

  +maxW(560px)
    width: calc(100vw - 32px)



.modal.fade-anim
  opacity: 0
  transition: opacity 200ms ease-in-out

.modal.fade-anim.in
  opacity: 1

  .modal-content
    animation: modal-fade-in-top 0.3s


.modal.fade-anim.scroll
  align-items: flex-start
  overflow-y: auto

@keyframes modal-fade-in-top
  0%
    opacity: 0
    transform: translateY(-40px)

  100%
    opacity: 1
    transform: translateY(0)
