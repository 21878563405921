html
  box-sizing: border-box

body
  min-width: 360px
  margin: 0
  color: var(--primary-color)
  font-family: var(--main-font-family)
  font-size: 16px
  font-weight: normal
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: normal
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

a:hover
  text-decoration: none

input, textarea, select, button
  font-weight: 500
  outline: none

*,
*:before,
*:after
  box-sizing: inherit

article, aside, footer, header, nav, section
  display: block

h1,h2,h3,h4,h5,h6,p,ul
  margin: 0
  padding: 0

li
  list-style-type: none

input, select, textarea
  transition: background-color 30000s, color 30000s, filter 30000s

  &:-webkit-autofill
    animation-duration: 30000s
    animation-name: onautofillstart

  &:not(:-webkit-autofill)
    animation-duration: 30000s
    animation-name: onautofillcancel

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

  &[type=number]
    -moz-appearance: textfield

// Flex
.flex-wrap
  display: flex
  flex-wrap: wrap

.flex-center
  justify-content: center
  align-items: center

.grid
  padding-left: 16px
  padding-right: 16px

  &-row
    display: flex
    flex-wrap: wrap
    margin-left: -16px
    margin-right: -16px

  &-col
    padding-left: 16px
    padding-right: 16px

// inlineSVG
.inline-svg
  display: inline-block

  svg
    width: inherit
    height: inherit
    fill: inherit

  &__icon
    display: inline-block

    &.btn-circle
      display: flex
      align-items: center
      justify-content: center
      width: 48px
      height: 48px
      border-radius: 50%
      background-color: $porcelain
      cursor: pointer
      transition: all 0.3s

      &:hover
        background-color: var(--tint-one-color)

        svg
          fill: var(--main-color)

    svg
      width: 24px
      height: 24px
      fill: $gray-chateau
      transition: all 0.3s

  &--program
    svg
      .base-color1
        fill: #80878E

      .base-color2
        fill: #CED4D9

.programs-item
  &.active,
  &:hover
    .inline-svg
      &--program
        .base-color1
          fill: var(--main-color)

        .base-color2
          fill: var(--tint-three-color)

@keyframes onautofillstart
  0%
    opacity: 1

@keyframes onautofillcancel
  0%
    opacity: 1
