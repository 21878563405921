.stepper
  &__title
    margin-bottom: 40px

    +maxW(450px)
      margin-bottom: 24px
      font-size: 24px

  &__description
    margin: -32px 0 40px
    font-size: 18px
    font-weight: 600
    line-height: 1.2
    color: #1d1d1f

    +maxW(450px)
      margin-top: -10px

  &__btn-group
    margin: 0 -16px

    +maxW(540px)
      margin: 0

    .btn
      flex-direction: column
      margin: 0 16px
      min-width: 160px

      +maxW(780px)
        margin: 8px

    &.column
      display: flex
      flex-direction: column
      min-width: 540px

      +maxW(560px)
        width: 100%
        min-width: unset

      .btn
        margin: 8px 0

    &.images
      display: flex
      flex-wrap: wrap
      justify-content: center

      .btn
        padding: 32px 15px
        border-radius: 16px

        +maxW(780px)
          padding: 10px 8px
          min-width: 40%
          width: 45%
          background-color: $porcelain
          border-radius: 8px

          img
            width: 100%

        &:hover
          .active
            display: block

          .image
            display: none

        img
          margin-bottom: 25px

          &.active
            display: none

  &__images-group
    display: inline-flex
    flex-wrap: wrap
    justify-content: center
    margin: 0 -16px

    +maxW(450px)
      margin: 0

    .image-item
      display: flex
      flex-direction: column
      padding: 24px 40px 32px
      margin: 0 16px
      width: 195px
      border-radius: 16px
      background-color: transparent
      transition: background-color 0.3s
      cursor: pointer

      &:hover
        &.bad
          background-color: #fbdbdb

        &.poor
          background-color: #fde6de

        &.fair
          background-color: #fff0cc

        &.good
          background-color: #dff5dc

        &.great
          background-color: #dbf2ff

      .image
        +maxW(451px)
          display: none

        &.mobile
          display: none

          +maxW(450px)
            display: block

      .title
        margin: 24px 0 16px
        line-height: 1

      +maxW(450px)
        flex-direction: row
        align-items: center
        width: 100%
        padding: 8px 16px
        margin: 8px 0
        border-radius: 8px

        img
          width: 40px

        .title
          margin: 0
          margin-left: 16px

        .score
          margin-left: auto

        &.bad
          background-color: #fbdbdb

        &.poor
          background-color: #fde6de

        &.fair
          background-color: #fff0cc

        &.good
          background-color: #dff5dc

        &.great
          background-color: #dbf2ff



