@import "src/assets/sass/_variables"
@import "src/assets/sass/mixins/_all"

.form-group
  padding: 16px

.input-group
  position: relative

  .icon
    position: absolute
    width: 24px
    height: 24px
    z-index: 1
    left: 16px
    top: 19px

    svg
      width: 100%
      height: 100%

  .form-control
    width: 100%
    padding: 19px 19px 19px 48px
    font-family: 'Figtree'
    font-size: 20px
    font-weight: normal
    line-height: 1.3
    color: $shark
    border: unset
    box-shadow: inset 0px 0px 0px 1px $gray-chateau
    border-radius: 5px
    transition: box-shadow 0.3s

    &::placeholder
      color: $gray-chateau

    &:focus
      box-shadow: inset 0px 0px 0px 2px var(--main-color)

    &.error,
    &.ng-invalid.ng-dirty
      box-shadow: inset 0px 0px 0px 2px #e01010

    &:disabled
      color: $gray-chateau
      box-shadow: inset 0px 0px 0px 1px $athens-gray
      background-color: $porcelain
      cursor: no-drop

  .error
    position: absolute
    bottom: -24px

    span
      font-size: 14px
      font-weight: normal
      line-height: 1.4
      color: #e01010
