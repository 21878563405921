@import "src/assets/sass/_variables"

.color-white
  color: $white !important

.color-dark
  color: var(--dark-font)

.color-light
  color: var(--light-font)
