@import "src/assets/sass/_variables"
@import "src/assets/sass/mixins/_all"

button
  padding: 0
  font-family: var(--main-font-family)
  border: none
  box-shadow: none
  background-color: transparent

  &:focus,
  &:active
    outline: none

  &:hover
    cursor: pointer

.btn
  display: inline-flex
  align-items: center
  justify-content: center
  padding: 21px 40px
  font-size: 18px
  font-weight: 600
  line-height: 1.2
  color: $white
  border-radius: 8px
  transition: all 0.3s

  +maxW(540px)
    padding: 21px 30px

  &:hover
    background-position: right center
    cursor: pointer

  &:focus
    outline: none

  &:active,
  &.active
    background-position: left center


  &:disabled,
  &.disabled
    cursor: no-drop

  &--default
    background-color: var(--main-color)

    &:hover
      background-color: var(--shade-color)

    &:focus
      background-color: var(--shade-color)

    &:active,
    &.active
      background-color: var(--shade-color)

    &:disabled,
    &.disabled
      background-color: $athens-gray

  &--white
    color: $shark
    background-color: $white

    &:hover,
    &:active,
    &.active
      color: var(--shade-color)
      background-color: var(--tint-one-color)

    &:focus
      color: var(--shade-color)
      background-color: var(--tint-one-color)

    &:disabled,
    &.disabled
      color: $gray-chateau
      background-color: $white

  &--grey
    color: $shark
    background-color: $porcelain

    &:hover,
    &:active,
    &.active
      color: var(--main-color)
      background-color: var(--tint-one-color)

    &:focus
      color: var(--main-color)
      background-color: var(--tint-one-color)

    &:disabled,
    &.disabled
      color: $gray-chateau
      background-color: $porcelain

  &-group
    display: inline-block
    padding: 4px 2px
    border-radius: 8px
    background-color: var(--shade-color)

    .btn
      margin: 0 2px
      padding: 13px 24px
      border-radius: 5px

      &:hover,
      &:focus,
      &:active,
      &.active
        color: $white
        background-color: var(--main-color)

      &:disabled,
      &.disabled
        color: rgba(255, 255, 255, 1) !important
        background-color: unset

      &--default
        background-color: $porcelain
        color: $shark

        &:hover,
        &:focus,
        &:active,
        &.active
          background-color: $porcelain
          color: $shark

  .btn-spinner
    position: relative
    display: inline-block
    width: 19px
    height: 19px
    margin-left: -22px
    border-radius: 50%
    animation: 0.9s linear infinite three-quarters
    transition: 0.4s margin ease-out, 0.2s opacity ease-out
    background-image: conic-gradient(from 0.25turn, rgba(255, 255, 255, 0), $white)
    opacity: 0

    &::before
      content: ''
      position: absolute
      left: 2px
      top: 2px
      width: 15px
      height: 15px
      border-radius: 50%
      background: var(--main-color)

  &.is-loading
    .btn-spinner
      margin-left: 10px
      transition: 0.2s margin ease-in, 0.4s opacity ease-in
      opacity: 1

    &.btn--default
      background: $athens-gray
      color: $gray-chateau

      .btn-spinner
        background-image: conic-gradient(from 0.25turn, rgba(242, 90, 33, 0), var(--main-color))

        &::before
          background: $athens-gray

    &.btn--white
      background-color: $white
      color: rgb(29, 29, 31, 0.5)

      .btn-spinner
        background-image: conic-gradient(from 0.25turn, rgba(242, 90, 33, 0), var(--main-color))

        &::before
          background: $white

    &.btn--grey
      background-color: $porcelain
      color: rgb(29, 29, 31, 0.5)

      .btn-spinner
        background-image: conic-gradient(from 0.25turn, rgba(242, 90, 33, 0), var(--main-color))

        &::before
          background: $porcelain



@keyframes three-quarters
  0%
    transform: rotate(0)
  100%
    transform: rotate(360deg)
