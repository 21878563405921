@mixin maxW($maxw)
  @media (max-width: $maxw)
    @content

@mixin maxH($maxh)
  @media (max-height: $maxh)
    @content

@mixin minW($minw)
  @media (min-width: $minw)
    @content

@mixin minH($minh)
  @media (min-height: $minh)
    @content

@mixin min-media($point)
  @if $point==lg
    @media only screen and (min-width: 1200px)
      @content

  @else if $point==md
    @media only screen and (min-width: 1024px)
      @content

  @else if $point==xs
    @media only screen and (min-width: 768px)
      @content

  @else if $point==xxs
    @media only screen and (min-width: 375px)
      @content

  @else
    @media only screen and (min-width: $point)
      @content

@mixin max-media($point)
  @if $point==lg
    @media only screen and (max-width: 1199px)
      @content

  @else if $point==md
    @media only screen and (max-width: 1023px)
      @content

  @else if $point==xs
    @media only screen and (max-width: 767px)
      @content

  @else
    @media only screen and (max-width: $point)
      @content

@mixin min-height-media($point)
  @media only screen and (min-height: $point)
    @content

@mixin max-height-media($point)
  @media only screen and (max-height: $point)
    @content
