@import "src/assets/sass/_variables"
@import "src/assets/sass/mixins/_all"

h1, h2, h3, h4, h5
  color: var(--primary-color)

h1
  font-size: 54px
  font-weight: 700
  line-height: 1.2

  +max-media(xs)
    font-size: 36px
    line-height: 1.31

h2
  font-size: 36px
  font-weight: 700
  line-height: 1.31

  +max-media(xs)
    font-size: 24px
    line-height: 1.2

h3
  font-size: 24px
  font-weight: 700
  line-height: 1.2

h4
  font-size: 18px
  font-weight: 600
  line-height: 1.2

h5
  font-size: 14px
  font-weight: 600
  line-height: 1.2
